<template>
    <Datepicker
        v-model="proxyModelValue"
        :day-names="days"
        :inline="inline"
        :vertical="vertical"
        :multi-dates="multiDates"
        :disabled="disabled"
        :allowed-dates="allowedDates"
        :month-change-on-scroll="monthChangeOnScroll"
        :highlight="highlightedDates"
        :auto-apply="autoApply"
        :close-on-auto-apply="closeOnAutoApply"
        :model-type="modelType"
        :no-today="noToday"
        :min-date="minDate"
        :max-date="maxDate"
        :prevent-min-max-navigation="preventMinMaxNavigation"
    >
        <template #input-icon>
            <CalendarIcon class="input-slot-image" />
        </template>
        <template #day="{ day }">
            <div class="calendar-day">
                {{ day }}
            </div>
        </template>
    </Datepicker>
</template>

<script>
    import Datepicker from '@vuepic/vue-datepicker';
    import CalendarIcon from '@/assets/icons/inputs/calendar.svg?inline';

    export default {
        name: 'CRDatepicker',
        components: {
            Datepicker,
            CalendarIcon,
        },
        props: {
            modelValue: {
                type: [String, Array],
                default: '',
            },
            inline: {
                type: Boolean,
                default: false,
            },
            noToday: {
                type: Boolean,
                default: false,
            },
            modelType: {
                type: String,
                default: null,
            },
            vertical: {
                type: Boolean,
                default: true,
            },
            multiDates: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            autoApply: {
                type: Boolean,
                default: true,
            },
            closeOnAutoApply: {
                type: Boolean,
                default: false,
            },
            monthChangeOnScroll: {
                type: Boolean,
                default: false,
            },
            minDate: {
                type: String,
                default: null,
            },
            maxDate: {
                type: String,
                default: null,
            },
            preventMinMaxNavigation: {
                type: Boolean,
                default: false,
            },
            allowedDates: {
                type: Array,
                default: () => [],
            },
            highlightedDates: {
                type: Array,
                default: () => [],
            },
        },
        emits: ['update:modelValue', 'onDateClick'],
        data() {
            return {
                days: [
                    this.$t('weekDays.mon'),
                    this.$t('weekDays.tue'),
                    this.$t('weekDays.wed'),
                    this.$t('weekDays.thu'),
                    this.$t('weekDays.fri'),
                    this.$t('weekDays.sat'),
                    this.$t('weekDays.sun'),
                ],
            };
        },
        computed: {
            proxyModelValue: {
                get() {
                    return this.modelValue;
                },

                set(val) {
                    this.$emit('update:modelValue', val);
                    this.$emit('onDateClick', this.modelValue);
                },
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss';

    .input-slot-image {
        margin-left: 18px;
    }
</style>
