import { translation } from '@/helpers/TranslationHelper';

export const getLabelForDeliveryTime = (deliveryTime) => {
    let label = `${deliveryTime.startTime} - ${deliveryTime.endTime}`;
    if (deliveryTime.deliverDayBefore) label += ` ${translation('otherSettings.deliveryDayBeforeLabel')}`;
    return label;
};

export const getAdminLabelForDeliveryTime = (deliveryTime) => {
    let label = `${deliveryTime.startTime} - ${deliveryTime.endTime} (${deliveryTime.city.name})`;
    if (deliveryTime.deliverDayBefore) label += ` ${translation('otherSettings.deliveryDayBeforeLabel')}`;
    return label;
};
